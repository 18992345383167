function getUnit(param) {

    if (param == "speed") {
        if (sessionStorage.getItem("speed") == 0) {
            return 'km/h';
        }
        if (sessionStorage.getItem("speed") == 1) {
            return 'mph';
        }
    }

    if (param == "weight") {
        if (sessionStorage.getItem("weight") == 0) {
            return 'kg';
        }
        if (sessionStorage.getItem("weight") == 1) {
            return 'lb';
        }
    }

    if (param == "distance") {
        if (sessionStorage.getItem("distance") == 0) {
            return 'km';
        }
        if (sessionStorage.getItem("distance") == 1) {
            return 'miles';
        }
    }

    if (param == "volume") {
        if (sessionStorage.getItem("volume") == 0) {
            return 'litres';
        }
        if (sessionStorage.getItem("volume") == 1) {
            return 'US Gallons';
        }
        if (sessionStorage.getItem("volume") == 2) {
            return 'Imp. Gallons';
        }
    }
    if (param == "temperature") {
        if(sessionStorage.temperature == 0){
            return '°C';
        }else{
            return '°F';
        }
    }
}

function getUnitInput(input_type) {
    if (input_type == 1 || input_type == 17 || input_type == 23){
        if(sessionStorage.temperature == 0){
            return '°C';
        }else{
            return '°F';
        }
    }
    else if (input_type == 4 || input_type == 18) {
        return "%";
    }
    else if (input_type == 8 || input_type == 25) {
        if (sessionStorage.getItem("speed") == 0) {
            return 'km/h';
        }
        if (sessionStorage.getItem("speed") == 1) {
            return 'mph';
        }
    }
    else if (input_type == 24) {
        return "";
    }

    else if (input_type == 5) {
        return "wc";
    }
    else if (input_type == 19) {
        return "PPM";
    }
    else if (input_type == 20) {
        return "PPM";
    }
    else if (input_type == 10) {
        return "";
    }
    else if (input_type == 6) {
        return "";
    }
    else if (input_type == 14) {
        return "pulse/min";
    }
    else if (input_type == 21 || input_type == 22) {
        return "lumen";
    }
    else if (input_type == 26) {
        return "hPa";
    }
}

function convertTemperature(celcius){
    if (isNaN(celcius)){
        return celcius;
    }
    if(sessionStorage.temperature == 0){
        return parseFloat(celcius).toFixed(1);
    }else{
        return parseFloat((celcius * 9 / 5 + 32)).toFixed(1);
    }
 }

 function calculateTHI(t,h){
     return (round((1.8 * t + 32) - ((0.55 -0.0055 * h) * (1.8 * t - 26)),1));
 }

 function convertSpeed(kph){

    if(sessionStorage.speed == 0){
        return parseFloat(kph).toFixed(1) + " km/h";
    }else{
        return parseFloat((kph / 1.609)).toFixed(1) + " mph";
    }
 }

 function convertDistance(km){
     if(sessionStorage.distance == 0){
        return parseFloat(km).toFixed(1);
    }else{
        return parseFloat((km / 1.609)).toFixed(1);
    }
}

 function convertWeight(lb){
    if (isNaN(lb)){
        return lb;
    }
    if(sessionStorage.weight == 0){
        return parseFloat((lb / 2.20462)).toFixed(1);
    }
    else {
        return  parseFloat(lb).toFixed(1);
    }

 }

 function convertWeight_kg(kg,decimal){
    if (isNaN(kg)){
        return kg;
    }
    if(sessionStorage.weight == 0){
        return parseFloat(kg).toFixed(decimal);
    }
    else {
        return  parseFloat(kg * 2.20462).toFixed(decimal);
    }

 }

 function convertWeightUnit(lb){

    if (isNaN(lb)){
        return lb;
    }

    if(sessionStorage.weight == 0){
        return parseFloat((lb / 2.20462)).toFixed(1) + ' kg';
    }
    else {
        return  parseFloat(lb).toFixed(1) + ' lb';
    }
}

 function convertVolume(litre){
     if(sessionStorage.volume == 1){
        return parseFloat((litre * 0.26417)).toFixed(1);
    }
    if(sessionStorage.volume == 2){
        return parseFloat((litre * 0.219969)).toFixed(1)
    }
    else{
        return parseFloat(litre).toFixed(1) ;
    }
}

function getApproxWindDirection(degree) {
    var direction = "";
    if (degree >= 348.75 && degree <= 360) {
        direction = "n";
    } else if (degree >= 0 && degree <= 11.25) {
        direction = "n";
    } else if (degree > 11.25 && degree < 33.75) {
        direction = "nne";
    } else if (degree > 33.75 && degree < 56.25) {
        direction = "ne";
    } else if (degree > 56.25 && degree < 78.75) {
        direction = "ene";
    } else if (degree > 78.75 && degree < 101.25) {
        direction = "e";
    } else if (degree > 101.25 && degree < 123.75) {
        direction = "ese";
    } else if (degree > 123.75 && degree < 146.25) {
        direction = "se";
    } else if (degree > 146.25 && degree < 168.75) {
        direction = "sse";
    } else if (degree > 168.75 && degree < 191.25) {
        direction = "s";
    } else if (degree > 191.25 && degree < 213.75) {
        direction = "ssw";
    } else if (degree > 213.75 && degree < 236.25) {
        direction = "sw";
    } else if (degree > 236.25 && degree < 258.75) {
        direction = "wsw";
    } else if (degree > 258.75 && degree < 281.25) {
        direction = "w";
    } else if (degree > 281.25 && degree < 303.75) {
        direction = "wnw";
    } else if (degree > 303.75 && degree < 326.25) {
        direction = "nw";
    } else if (degree > 326.25 && degree < 348.75) {
        direction = "nnw";
    }
    return direction;
}

function roundInputValue(value , input){
    if (input == "static pressure"){
        return parseFloat(value).toFixed(3);
    }else if (input == "barometric pressure"){
        return parseFloat(value).toFixed(0);
    }else{
        return parseFloat(value).toFixed(1);
    }
}

function getInputType(input_type){
    if (input_type == 1 || input_type == 17 || input_type == 23){
        return "temperature";
    }
    else if (input_type == 4 || input_type == 18) {
        return "humidity";
    }
    else if (input_type == 8 || input_type == 25) {
        return "wind_speed";
    }
    else if (input_type == 24) {
        return "wind_direction";
    }
    else if (input_type == 5) {
        return "static pressure";
    }
    else if (input_type == 19) {
        return "co2";
    }
    else if (input_type == 20) {
        return "nh3";
    }
    else if (input_type == 10) {
        return "digital_input";
    }
    else if (input_type == 6) {
        return "rain_sensor";
    }
    else if (input_type == 14) {
        return "pulse";
    }
    else if (input_type == 21 || input_type == 22) {
        return "light";
    }
    else if (input_type == 26) {
        return "barometric_pressure";
    }
}



//Rounds the input value for all input types
function roundInput(value, input_type) {
    var convertedVal;
    if (value != null) {
        convertedVal = value;
    } else {
        convertedVal = 35757;
    }
    if (input_type == 1 || input_type == 17 || input_type == 23 || input_type == 28 || input_type == 101) { //Temperature
        if (sessionStorage.temperature == 0) { //Celcius
            convertedVal = (round(value, 1));
            if (convertedVal < 180 && convertedVal > -100) {
                convertedVal = convertedVal + " "+$.i18n("unt_c");
            } else {
                convertedVal = "-- "+$.i18n("unt_c");
            }
        } else if (sessionStorage.temperature == 1) { //Farenheit
            convertedVal = value * 1.8;
            convertedVal = (round(convertedVal, 1));
            if (convertedVal < 250 && convertedVal > -150) {
                convertedVal = round(convertedVal + 32, 1) + " "+$.i18n("unt_f");
            } else {
                convertedVal = "-- "+$.i18n("unt_f");
            }
        }
    } else if (input_type == 4 || input_type == 18) { //Humidity
        if (value < 101 && value > -1) {
            convertedVal = (round(value, 1));
            convertedVal = convertedVal + "%";
        } else {
            convertedVal = "--%";
        }
    } else if (input_type == 5) { //Static
        if (convertedVal < 5) {
            convertedVal = (round(value, 3));
            convertedVal = convertedVal + " "+$.i18n("unt_wc");
        } else {
            convertedVal = "-- "+$.i18n("unt_wc");
        }
    } else if (input_type == 6) { //Rain Detect
        if (value == 1) {
            convertedVal = $.i18n("msg_raining");
        } else if (value == 0) {
            convertedVal = $.i18n("msg_not_raining");
        } else if (value > 1 || value < 0) {
            convertedVal = "--";
        }
    } else if (input_type == 8 || input_type == 25) { //Windspeed
        if (sessionStorage.speed == 0) { //km/h
            convertedVal = (round(value, 1));
            if (convertedVal < 180 && convertedVal > -1) {
                convertedVal = convertedVal + " "+$.i18n("unt_kmh");
            } else {
                convertedVal = "-- "+$.i18n("unt_kmh");
            }
        } else if (sessionStorage.speed == 1) { //mph
            convertedVal = value * 0.6214;
            convertedVal = (round(convertedVal, 1));
            if (convertedVal < 250 && convertedVal > -1) {
                convertedVal = convertedVal + " "+$.i18n("unt_mph");
            } else {
                convertedVal = "-- "+$.i18n("unt_mph");
            }
        }
    } else if (input_type == 9) { //Data Only
        convertedVal = $.i18n("sts_data_only");
    } else if (input_type == 10) { //Digital
        if (value == 1) {
            convertedVal = $.i18n("closed");
        } else if (value == 0) {
            convertedVal = $.i18n("open");
        } else if (value > 1 || value < 0) {
            convertedVal = "--";
        }
    }else if (input_type == "10_3") { //Digital
        if (value == 1) {
            convertedVal = $.i18n("lbl_full");
        } else if (value == 0) {
            convertedVal = $.i18n("lbl_empty");
        } else if (value > 1 || value < 0) {
            convertedVal = "--";
        }
    } else if (input_type == 12) { //0 - 10 Volt
    } else if (input_type == 13) { //Direction
    } else if (input_type == 14) {
        if (value < 35700) {
            if (sessionStorage.water_unit == 0) {
                convertedVal = (round(value, 1)) + " "+$.i18n("unt_lpm");
            } else if (sessionStorage.water_unit == 1) {
                convertedVal = (round(value * 0.264172, 1)) + " "+$.i18n("unt_gpm");
            } else if (sessionStorage.water_unit == 2) {
                convertedVal = (round(value * 0.219969, 1)) + " "+$.i18n("unt_gpm");
            }
        } else {
            if (sessionStorage.water_unit > 0) {
                convertedVal = "-- "+$.i18n("unt_gpm");
            } else {
                convertedVal = "-- "+$.i18n("unt_lpm");
            }
        }
    } else if (input_type == 15) { //Wind Chill
        if (sessionStorage.temperature == 0) { //Celcius
            convertedVal = (round(value, 1));
            if (convertedVal < 180 && convertedVal > -100) {
                convertedVal = convertedVal + $.i18n("unt_c");
            } else {
                convertedVal = "-- "+$.i18n("unt_c");
            }
        } else if (sessionStorage.temperature == 1) { //Farenheit
            convertedVal = value * 1.8;
            convertedVal = (round(convertedVal, 1));
            if (convertedVal < 250 && convertedVal > -150) {
                convertedVal = convertedVal + 32 + " "+$.i18n("unt_f");
            } else {
                convertedVal = "-- "+$.i18n("unt_f");
            }
        }
    } else if (input_type == 16) { //THI
        convertedVal = (round(value, 1));
        if (convertedVal <= 100 && convertedVal >= 0) {
            convertedVal = convertedVal + " "+$.i18n("thi");
        } else {
            convertedVal = "-- "+$.i18n("thi");
        }
    } else if (input_type == 19) { //Co2
        convertedVal = (round(value, 1));
        if (convertedVal < 30000 && convertedVal > 0) {
            convertedVal = convertedVal + " "+$.i18n("unt_ppm");
        } else {
            convertedVal = "-- "+$.i18n("unt_ppm");
        }
    } else if (input_type == 20) { //Amonia
        convertedVal = (round(value, 1));
        if (convertedVal <= 30000 && convertedVal >= 0) {
            convertedVal = convertedVal + " "+$.i18n("unt_ppm");
        } else {
            convertedVal = "-- "+$.i18n("unt_ppm");
        }
    } else if (input_type == 21) { //Lux 100
        convertedVal = (round(value, 1));
        if (convertedVal <= 100 && convertedVal >= 0) {
            convertedVal = convertedVal + " "+$.i18n("unt_lx");
        } else {
            convertedVal = "-- "+$.i18n("unt_lx");
        }
    } else if (input_type == 22) { //LUX1000
        convertedVal = (round(value, 1));
        if (convertedVal <= 1000 && convertedVal >= 0) {
            convertedVal = convertedVal + " "+$.i18n("unt_lx");
        } else {
            convertedVal = "-- "+$.i18n("unt_lx");
        }
    } else if (input_type == 24) { //Wind direction
        if (parseInt(sessionStorage.wind_unit) === 0) {
            if (convertedVal <= 360 && convertedVal >= 0) {
                convertedVal = getApproxWindDirection(convertedVal);
            } else {
                convertedVal = "-- "+$.i18n("cmn_na")+" --";
            }
        } else {
            if (convertedVal <= 360 && convertedVal >= 0) {
                convertedVal = convertedVal + $.i18n("unt_deg");
            } else {
                convertedVal = "-- "+$.i18n("unt_deg");
            }
        }
    } else if (input_type == 26) { //Barometric Pressure
        convertedVal = (round(value, 0));
        if (convertedVal <= 1100 && convertedVal >= 800) {
            convertedVal = convertedVal + " "+$.i18n("unt_hpa");
        } else {
            convertedVal = "-- "+$.i18n("unt_hpa");
        }
    }
    return convertedVal;
}



//Ronds a number to a specific precision
function round(value, precision) {
    var multiplier = Math.pow(10, precision || 0);
    var rounded = Math.round(value * multiplier) / multiplier;
    return rounded;
}

/////////////////////////////////////////////////////////////MD5//////////////////////////////////////////////////////////////////////////////////////////

function MD5(string){




    function RotateLeft(lValue, iShiftBits) {


        return (lValue<<iShiftBits) | (lValue>>>(32-iShiftBits));


    }




    function AddUnsigned(lX,lY) {


        var lX4,lY4,lX8,lY8,lResult;


        lX8 = (lX & 0x80000000);


        lY8 = (lY & 0x80000000);


        lX4 = (lX & 0x40000000);


        lY4 = (lY & 0x40000000);


        lResult = (lX & 0x3FFFFFFF)+(lY & 0x3FFFFFFF);


        if (lX4 & lY4) {


            return (lResult ^ 0x80000000 ^ lX8 ^ lY8);


        }


        if (lX4 | lY4) {


            if (lResult & 0x40000000) {


                return (lResult ^ 0xC0000000 ^ lX8 ^ lY8);


            } else {


                return (lResult ^ 0x40000000 ^ lX8 ^ lY8);


            }


        } else {


            return (lResult ^ lX8 ^ lY8);


        }


     }




     function F(x,y,z) { return (x & y) | ((~x) & z); }


     function G(x,y,z) { return (x & z) | (y & (~z)); }


     function H(x,y,z) { return (x ^ y ^ z); }


    function I(x,y,z) { return (y ^ (x | (~z))); }




    function FF(a,b,c,d,x,s,ac) {


        a = AddUnsigned(a, AddUnsigned(AddUnsigned(F(b, c, d), x), ac));


        return AddUnsigned(RotateLeft(a, s), b);


    }




    function GG(a,b,c,d,x,s,ac) {


        a = AddUnsigned(a, AddUnsigned(AddUnsigned(G(b, c, d), x), ac));


        return AddUnsigned(RotateLeft(a, s), b);


    }




    function HH(a,b,c,d,x,s,ac) {


        a = AddUnsigned(a, AddUnsigned(AddUnsigned(H(b, c, d), x), ac));


        return AddUnsigned(RotateLeft(a, s), b);


    }




    function II(a,b,c,d,x,s,ac) {


        a = AddUnsigned(a, AddUnsigned(AddUnsigned(I(b, c, d), x), ac));


        return AddUnsigned(RotateLeft(a, s), b);


    }




    function ConvertToWordArray(string) {


        var lWordCount;


        var lMessageLength = string.length;


        var lNumberOfWords_temp1=lMessageLength + 8;


        var lNumberOfWords_temp2=(lNumberOfWords_temp1-(lNumberOfWords_temp1 % 64))/64;


        var lNumberOfWords = (lNumberOfWords_temp2+1)*16;


        var lWordArray=Array(lNumberOfWords-1);


        var lBytePosition = 0;


        var lByteCount = 0;


        while ( lByteCount < lMessageLength ) {


            lWordCount = (lByteCount-(lByteCount % 4))/4;


            lBytePosition = (lByteCount % 4)*8;


            lWordArray[lWordCount] = (lWordArray[lWordCount] | (string.charCodeAt(lByteCount)<<lBytePosition));


            lByteCount++;


        }


        lWordCount = (lByteCount-(lByteCount % 4))/4;


        lBytePosition = (lByteCount % 4)*8;


        lWordArray[lWordCount] = lWordArray[lWordCount] | (0x80<<lBytePosition);


        lWordArray[lNumberOfWords-2] = lMessageLength<<3;


        lWordArray[lNumberOfWords-1] = lMessageLength>>>29;


        return lWordArray;


    }




    function WordToHex(lValue) {


        var WordToHexValue="",WordToHexValue_temp="",lByte,lCount;


        for (lCount = 0;lCount<=3;lCount++) {


            lByte = (lValue>>>(lCount*8)) & 255;


            WordToHexValue_temp = "0" + lByte.toString(16);


            WordToHexValue = WordToHexValue + WordToHexValue_temp.substr(WordToHexValue_temp.length-2,2);


        }


        return WordToHexValue;


    }




    function Utf8Encode(string) {


        string = string.replace(/\r\n/g,"\n");


        var utftext = "";




        for (var n = 0; n < string.length; n++) {




            var c = string.charCodeAt(n);




            if (c < 128) {


                utftext += String.fromCharCode(c);


            }


            else if((c > 127) && (c < 2048)) {


                utftext += String.fromCharCode((c >> 6) | 192);


                utftext += String.fromCharCode((c & 63) | 128);


            }


            else {


                utftext += String.fromCharCode((c >> 12) | 224);


                utftext += String.fromCharCode(((c >> 6) & 63) | 128);


                utftext += String.fromCharCode((c & 63) | 128);


            }




        }




        return utftext;


    }




    var x=Array();


    var k,AA,BB,CC,DD,a,b,c,d;


    var S11=7, S12=12, S13=17, S14=22;


    var S21=5, S22=9 , S23=14, S24=20;


    var S31=4, S32=11, S33=16, S34=23;


    var S41=6, S42=10, S43=15, S44=21;




    string = Utf8Encode(string);




    x = ConvertToWordArray(string);




    a = 0x67452301; b = 0xEFCDAB89; c = 0x98BADCFE; d = 0x10325476;




    for (k=0;k<x.length;k+=16) {


        AA=a; BB=b; CC=c; DD=d;


        a=FF(a,b,c,d,x[k+0], S11,0xD76AA478);


        d=FF(d,a,b,c,x[k+1], S12,0xE8C7B756);


        c=FF(c,d,a,b,x[k+2], S13,0x242070DB);


        b=FF(b,c,d,a,x[k+3], S14,0xC1BDCEEE);


        a=FF(a,b,c,d,x[k+4], S11,0xF57C0FAF);


        d=FF(d,a,b,c,x[k+5], S12,0x4787C62A);


        c=FF(c,d,a,b,x[k+6], S13,0xA8304613);


        b=FF(b,c,d,a,x[k+7], S14,0xFD469501);


        a=FF(a,b,c,d,x[k+8], S11,0x698098D8);


        d=FF(d,a,b,c,x[k+9], S12,0x8B44F7AF);


        c=FF(c,d,a,b,x[k+10],S13,0xFFFF5BB1);


        b=FF(b,c,d,a,x[k+11],S14,0x895CD7BE);


        a=FF(a,b,c,d,x[k+12],S11,0x6B901122);


        d=FF(d,a,b,c,x[k+13],S12,0xFD987193);


        c=FF(c,d,a,b,x[k+14],S13,0xA679438E);


        b=FF(b,c,d,a,x[k+15],S14,0x49B40821);


        a=GG(a,b,c,d,x[k+1], S21,0xF61E2562);


        d=GG(d,a,b,c,x[k+6], S22,0xC040B340);


        c=GG(c,d,a,b,x[k+11],S23,0x265E5A51);


        b=GG(b,c,d,a,x[k+0], S24,0xE9B6C7AA);


        a=GG(a,b,c,d,x[k+5], S21,0xD62F105D);


        d=GG(d,a,b,c,x[k+10],S22,0x2441453);


        c=GG(c,d,a,b,x[k+15],S23,0xD8A1E681);


        b=GG(b,c,d,a,x[k+4], S24,0xE7D3FBC8);


        a=GG(a,b,c,d,x[k+9], S21,0x21E1CDE6);


        d=GG(d,a,b,c,x[k+14],S22,0xC33707D6);


        c=GG(c,d,a,b,x[k+3], S23,0xF4D50D87);


        b=GG(b,c,d,a,x[k+8], S24,0x455A14ED);


        a=GG(a,b,c,d,x[k+13],S21,0xA9E3E905);


        d=GG(d,a,b,c,x[k+2], S22,0xFCEFA3F8);


        c=GG(c,d,a,b,x[k+7], S23,0x676F02D9);


        b=GG(b,c,d,a,x[k+12],S24,0x8D2A4C8A);


        a=HH(a,b,c,d,x[k+5], S31,0xFFFA3942);


        d=HH(d,a,b,c,x[k+8], S32,0x8771F681);


        c=HH(c,d,a,b,x[k+11],S33,0x6D9D6122);


        b=HH(b,c,d,a,x[k+14],S34,0xFDE5380C);


        a=HH(a,b,c,d,x[k+1], S31,0xA4BEEA44);


        d=HH(d,a,b,c,x[k+4], S32,0x4BDECFA9);


        c=HH(c,d,a,b,x[k+7], S33,0xF6BB4B60);


        b=HH(b,c,d,a,x[k+10],S34,0xBEBFBC70);


        a=HH(a,b,c,d,x[k+13],S31,0x289B7EC6);


        d=HH(d,a,b,c,x[k+0], S32,0xEAA127FA);


        c=HH(c,d,a,b,x[k+3], S33,0xD4EF3085);


        b=HH(b,c,d,a,x[k+6], S34,0x4881D05);


        a=HH(a,b,c,d,x[k+9], S31,0xD9D4D039);


        d=HH(d,a,b,c,x[k+12],S32,0xE6DB99E5);


        c=HH(c,d,a,b,x[k+15],S33,0x1FA27CF8);


        b=HH(b,c,d,a,x[k+2], S34,0xC4AC5665);


        a=II(a,b,c,d,x[k+0], S41,0xF4292244);


        d=II(d,a,b,c,x[k+7], S42,0x432AFF97);


        c=II(c,d,a,b,x[k+14],S43,0xAB9423A7);


        b=II(b,c,d,a,x[k+5], S44,0xFC93A039);


        a=II(a,b,c,d,x[k+12],S41,0x655B59C3);


        d=II(d,a,b,c,x[k+3], S42,0x8F0CCC92);


        c=II(c,d,a,b,x[k+10],S43,0xFFEFF47D);


        b=II(b,c,d,a,x[k+1], S44,0x85845DD1);


        a=II(a,b,c,d,x[k+8], S41,0x6FA87E4F);


        d=II(d,a,b,c,x[k+15],S42,0xFE2CE6E0);


        c=II(c,d,a,b,x[k+6], S43,0xA3014314);


        b=II(b,c,d,a,x[k+13],S44,0x4E0811A1);


        a=II(a,b,c,d,x[k+4], S41,0xF7537E82);


        d=II(d,a,b,c,x[k+11],S42,0xBD3AF235);


        c=II(c,d,a,b,x[k+2], S43,0x2AD7D2BB);


        b=II(b,c,d,a,x[k+9], S44,0xEB86D391);


        a=AddUnsigned(a,AA);


        b=AddUnsigned(b,BB);


        c=AddUnsigned(c,CC);


        d=AddUnsigned(d,DD);


    }





    var temp = WordToHex(a)+WordToHex(b)+WordToHex(c)+WordToHex(d);





    return temp.toLowerCase();

    };


    function random_rgba() {
        var o = Math.round,
            r = Math.random,
            s = 255;
        return 'rgba(' + o(r() * s) + ',' + o(r() * s) + ',' + o(r() * s) + ',' + 2 + ')';
    }